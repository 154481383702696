import { Services } from "../../../services";

class PabrikanApi {
  getPage(page, dataLength, key) {
    return Services.get(`/pabrikan/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getKodeItem() {
    return Services.get(`/pabrikan/no_baru`);
  }
  create(data) {
    return Services.post("/pabrikan", data);
  }
  update(data) {
    return Services.put("/pabrikan", data);
  }
  delete(id_pabrikan) {
    return Services.post("/pabrikan/delete", id_pabrikan);
  }
  show(id_pabrikan) {
    return Services.put("/pabrikan/show", id_pabrikan);
  }
  hide(id_pabrikan) {
    return Services.put("/pabrikan/hide", id_pabrikan);
  }
  single(value) {
    return Services.get("/pabrikan/single", { params: { ...value } });
  }
}

export default new PabrikanApi();
