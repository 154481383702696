import { Services, UploadServices } from "../../../../services";

class AsetSistemApi {
  getPage(page, dataLength, key) {
    return Services.get(`/asetsistemsistem/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getKode() {
    return Services.get("/asetsistemsistem/no_baru?id_kategori_aset=13");
  }

  getKategoriAset() {
    return Services.get("/asetsistemsistem/kategori");
  }

  getKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  getPabrikan() {
    return Services.get("/pabrikan/dropdown");
  }

  getUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  getProvinsi() {
    return Services.get("/wilayah/dropdown_provinsi");
  }

  getKabupaten(id) {
    return Services.get("/wilayah/dropdown_kabupaten?id_provinsi=" + id);
  }

  getKecamatan(id) {
    return Services.get("/wilayah/dropdown_kecamatan?id_kabupaten=" + id);
  }

  getDesa(id) {
    return Services.get("/wilayah/dropdown_desa?id_kecamatan=" + id);
  }

  getSingle(id_item_aset) {
    return Services.get("/asetsistemsistem/single/?id_item_aset=" + id_item_aset);
  }

  create(data) {
    return Services.post("/asetsistemsistem", data);
  }

  update(data) {
    return Services.put("/asetsistemsistem", data);
  }

  delete(id) {
    return Services.post("/asetsistemsistem/delete", id);
  }

  show(id) {
    return Services.put("/asetsistemsistem/show", id);
  }

  hide(id) {
    return Services.put("/asetsistemsistem/hide", id);
  }

  postPabrikan(value) {
    return Services.post("/pabrikan", value);
  }

  getGolongan() {
    return Services.get("/asetsistemsistem/dropdown_golongan_aset");
  }

  getKondisi() {
    return Services.get("/asetsistemsistem/dropdown_kondisi_aset");
  }

  getJenis() {
    return Services.get("/asetsistemsistem/dropdown_jenis_aset");
  }
  uploadFileMultiple(data) {
    return UploadServices.post("/upload/aset_multiple", data);
  }

  uploadFile(data) {
    return Services.post("/asetsistemsistem/gambar", data);
  }

  deleteFile(id) {
    return Services.post("/asetsistemsistem/gambar_delete", id);
  }

  dropdown(value) {
    return Services.get("/asetsistemsistem/dropdown", { params: { ...value } });
  }

  single(value) {
    return Services.get("/asetsistemsistem/single", { params: { ...value } });
  }

  no_baru(value) {
    return Services.get("/asetsistemsistem/no_baru", { params: { ...value } });
  }

  penyusutan(value) {
    return Services.get("/penyusutan_saldo_menurun/penyusutan", { params: { ...value } });
  }

  updatePenyusutan(value) {
    return Services.put("/asetsistemsistem/penyusutan", value);
  }

  singleCheck(value) {
    return Services.get("/asetsistemsistem/single_check", { params: { ...value } });
  }
  export() {
    return Services.get("/asetsistemsistem/xlsx");
  }
}

export default new AsetSistemApi();
