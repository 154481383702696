import Axios from "axios";

// const { REACT_APP_API_BASE_URL } = process.env;

const UploadServices = Axios.create({
  baseURL: "https://devapi.mgp.bhawanaerp.com/v1/upload/",
});

// Services.defaults.timeout = 10000;

UploadServices.interceptors.request.use((config) => {
  const token = "Bearer " + sessionStorage.getItem("token");

  config.headers = {
    "Api-Key": "TUdQQmFuZ3NhdFBhbnRla0FzdQ==",
    "Content-Type": "application/json",
  };

  return config;
});

export default UploadServices;
