import { Services, UploadServices } from "../../../../services";

class AsetKendaraanApi {
  getPage(page, dataLength, key) {
    return Services.get(`/asetkendaraan/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getKode() {
    return Services.get("/asetkendaraan/no_baru?id_kategori_aset=13");
  }

  getKategoriAset() {
    return Services.get("/asetkendaraan/kategori");
  }

  getKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  getPabrikan() {
    return Services.get("/pabrikan/dropdown");
  }

  getUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  getProvinsi() {
    return Services.get("/wilayah/dropdown_provinsi");
  }

  getKabupaten(id) {
    return Services.get("/wilayah/dropdown_kabupaten?id_provinsi=" + id);
  }

  getKecamatan(id) {
    return Services.get("/wilayah/dropdown_kecamatan?id_kabupaten=" + id);
  }

  getDesa(id) {
    return Services.get("/wilayah/dropdown_desa?id_kecamatan=" + id);
  }

  getSingle(id_item_aset) {
    return Services.get("/asetkendaraan/single/?id_item_aset=" + id_item_aset);
  }

  create(data) {
    return Services.post("/asetkendaraan", data);
  }

  update(data) {
    return Services.put("/asetkendaraan", data);
  }

  delete(id) {
    return Services.post("/asetkendaraan/delete", id);
  }

  show(id) {
    return Services.put("/asetkendaraan/show", id);
  }

  hide(id) {
    return Services.put("/asetkendaraan/hide", id);
  }

  postPabrikan(value) {
    return Services.post("/pabrikan", value);
  }

  getGolongan() {
    return Services.get("/asetkendaraan/dropdown_golongan_aset");
  }

  getKondisi() {
    return Services.get("/asetkendaraan/dropdown_kondisi_aset");
  }

  getJenis() {
    return Services.get("/asetkendaraan/dropdown_jenis_aset");
  }
  uploadFileMultiple(data) {
    return UploadServices.post("/upload/aset_multiple", data);
  }

  uploadFile(data) {
    return Services.post("/asetkendaraan/gambar", data);
  }

  deleteFile(id) {
    return Services.post("/asetkendaraan/gambar_delete", id);
  }

  dropdown(value) {
    return Services.get("/asetkendaraan/dropdown", { params: { ...value } });
  }

  single(value) {
    return Services.get("/asetkendaraan/single", { params: { ...value } });
  }

  singleCheck(value) {
    return Services.get("/asetkendaraan/single_check", { params: { ...value } });
  }

  no_baru(value) {
    return Services.get("/asetkendaraan/no_baru", { params: { ...value } });
  }

  penyusutan(value) {
    return Services.get("/penyusutan_saldo_menurun/penyusutan", { params: { ...value } });
  }

  updatePenyusutan(value) {
    return Services.put("/asetkendaraan/penyusutan", value);
  }
  export() {
    return Services.get("/asetkendaraan/xlsx");
  }
}

export default new AsetKendaraanApi();
