import {
  IoServerOutline,
  IoSpeedometerOutline,
  IoCashOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";
import Logo from "../assets/image/logoPuspa.png";
import {
  // Master
  GroupAset,
  KondisiAset,
  KategoriAset,
  Pabrikan,
  AsetBangunan,
  AsetInventarisKantor,
  AsetKendaraan,
  AsetMesinDanAlat,
  AsetTanah,
  EntitasAset,
  JenisAset,
  DataAsetTanah,
  DataAsetBangunan,
  AsetSistem,
  DataAsetSistem,
  DataAsetKendaraan,
  DataAsetInventarisKantor,
  DataAsetMesinDanAlat,
  AsetLain,
  DataAsetLain,

  // Transaksi
  // Transfer Produksi Alat Mesin
  TransferProduksiAlatMesin,
  DataTransferProduksiAlatMesin,

  // Pemeliharaan Aset
  ListPemeliharaanAset,

  // Laporan
  LaporanPenyusutanAset,
} from "../pages/ASM";

export default {
  LOGO: Logo,
  MODUL: "ASET",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["SUPA", "SUPA"],
    },
    {
      text: "Master Data",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["SUPA", "ASM"],
      menu: [
        {
          text: "Entitas Aset",
          link: "/asm/master/entitas-aset",
          hak: ["SUPA", "ASM_MAS_ENT"],
        },
        {
          text: "Grup Aset",
          link: "#",
          hak: [
            "SUPA", "ASM_REG_TNH", "ASM_REG_BGN", "ASM_REG_KDR",
            "ASM_REG_ALM", "ASM_REG_IVK", "ASM_REG_STM", "ASM_MAS_ASL"
          ],
          menu: [
            {
              text: "Aset Tanah",
              link: "/asm/master/item-aset/aset-tanah",
              hak: ["SUPA", "ASM_REG_TNH"],
            },
            {
              text: "Aset Bangunan",
              link: "/asm/master/item-aset/aset-bangunan",
              hak: ["SUPA", "ASM_REG_BGN"],
            },
            {
              text: "Aset Kendaraan",
              link: "/asm/master/item-aset/aset-kendaraan",
              hak: ["SUPA", "ASM_REG_KDR"],
            },
            {
              text: "Aset Alat dan Mesin",
              link: "/asm/master/item-aset/aset-alat-dan-mesin",
              hak: ["SUPA", "ASM_REG_ALM"],
            },
            {
              text: "Aset Inventaris",
              link: "/asm/master/item-aset/aset-inventaris-kantor",
              hak: ["SUPA", "ASM_REG_IVK"],
            },
            {
              text: "Aset Sistem-sistem",
              link: "/asm/master/item-aset/aset-sistem",
              hak: ["SUPA", "ASM_REG_STM"],
            },
            {
              text: "Aset Lainnya",
              link: "/asm/master/item-aset/aset-lain",
              hak: ["SUPA", "ASM_MAS_ASL"],
            },
          ],
        },
        {
          text: "Kategori Aset",
          link: "/asm/master/kategori-aset",
          hak: ["SUPA", "ASM_MAS_KTG"],
        },
        {
          text: "Jenis Aset",
          link: "/asm/master/jenis-aset",
          hak: ["SUPA", "ASM_MAS_JEN"],
        },
        {
          text: "Kondisi Aset",
          link: "/asm/master/kondisi-aset",
          hak: ["SUPA", "ASM_MAS_KDS"],
        },
        // {
        //   text: "Pabrikan Aset",
        //   link: "/asm/master/pabrikan",
        //   hak: ["SUPA", "ASM_MAS_PAB"],
        // },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: ["SUPA", "ASM"],
      menu: [
        // {
        //   text: "Transfer Alat Mesin Produksi",
        //   link: "/transaksi/transfer-produksi-alat-mesin",
        //   hak: ["SUPA", "ASM_TRN_TPA"],
        // },
        // {
        //   text: "Pemeliharaan Aset",
        //   link: "/transaksi/pemeliharaan-aset",
        //   hak: ["SUPA", "ASM_TRN_PA"],
        // },
        {
          text: "Coming Soon",
          link: "/404",
          hak: ["SUPA"],
        },
      ],
    },
    {
      text: "Laporan",
      type: "dropdown",
      icon: <IoDocumentTextOutline />,
      hak: ["SUPA", "ASM"],
      menu: [
        {
          text: "Laporan Penyusutan Aset",
          link: "/laporan/laporan-penyusutan-aset",
          hak: ["SUPA", "ASM_RPT_LPA"],
        },
      ],
    },

    //{
    //   text: "Profil",
    //   type: "dropdown",
    //   icon: <IoPersonCircleOutline />,
    //   hak: ["SUPA", "ASM"],
    //   menu: [
    //     {
    //       text: "Akun Saya",
    //       link: "/profil",
    //       hak: ["SUPA", "ASM"],
    //     },
    //     {
    //       text: "Ubah Akun",
    //       link: "/profil/ubah",
    //       hak: ["SUPA", "ASM"],
    //     },
    //     {
    //       text: "Ganti Password",
    //       link: "/profil/ganti-password",
    //       hak: ["SUPA", "ASM"],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    {
      exact: true,
      path: "/asm/master/grup-aset",
      page: GroupAset,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/entitas-aset",
      page: EntitasAset,
      hak: ["SUPA", "ASM_MAS_ENT"],
    },
    {
      exact: true,
      path: "/asm/master/kondisi-aset",
      page: KondisiAset,
      hak: ["SUPA", "ASM_MAS_KDS"],
    },
    {
      exact: true,
      path: "/asm/master/jenis-aset",
      page: JenisAset,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/kategori-aset",
      page: KategoriAset,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/pabrikan",
      page: Pabrikan,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-tanah",
      page: AsetTanah,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-tanah/:tipe",
      page: DataAsetTanah,
      hak: ["SUPA", "ASM", "ASM_REG_TNH"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-tanah/:tipe/:id",
      page: DataAsetTanah,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-bangunan",
      page: AsetBangunan,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-bangunan/:tipe",
      page: DataAsetBangunan,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-bangunan/:tipe/:id",
      page: DataAsetBangunan,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-sistem",
      page: AsetSistem,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-sistem/:tipe",
      page: DataAsetSistem,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-sistem/:tipe/:id",
      page: DataAsetSistem,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-kendaraan",
      page: AsetKendaraan,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-kendaraan/:tipe",
      page: DataAsetKendaraan,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-kendaraan/:tipe/:id",
      page: DataAsetKendaraan,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-alat-dan-mesin",
      page: AsetMesinDanAlat,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-alat-dan-mesin/:tipe",
      page: DataAsetMesinDanAlat,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-alat-dan-mesin/:tipe/:id",
      page: DataAsetMesinDanAlat,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-inventaris-kantor",
      page: AsetInventarisKantor,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-inventaris-kantor/:tipe",
      page: DataAsetInventarisKantor,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-inventaris-kantor/:tipe/:id",
      page: DataAsetInventarisKantor,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-lain",
      page: AsetLain,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-lain/:tipe",
      page: DataAsetLain,
      hak: ["SUPA", "ASM"],
    },
    {
      exact: true,
      path: "/asm/master/item-aset/aset-lain/:tipe/:id",
      page: DataAsetLain,
      hak: ["SUPA", "ASM"],
    },

    // TRANSAKSI
    // Transfer Produksi Alat Mesin
    {
      exact: true,
      path: "/transaksi/transfer-produksi-alat-mesin",
      page: TransferProduksiAlatMesin,
      hak: ["SUPA", "ASM_TRN_TPA"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-produksi-alat-mesin/data",
      page: DataTransferProduksiAlatMesin,
      hak: ["SUPA", "ASM_TRN_TPA"],
    },
    // Pemeliharaan Aset
    {
      exact: true,
      path: "/transaksi/pemeliharaan-aset",
      page: ListPemeliharaanAset,
      hak: ["SUPA", "ASM_TRN_PA"],
    },

    // LAPORAN
    {
      exact: true,
      path: "/laporan/laporan-penyusutan-aset",
      page: LaporanPenyusutanAset,
      hak: ["SUPA", "ASM_RPT_LPA"],
    },
  ],
};
