// React
import React from "react"

// Component
import {
  CRUDLayout, Th, THead, TBody, Tr, Td,
  TdFixed, ThFixed
} from "../../../../../../components"
import { DateConvert } from "utilities"

const PemeliharaanAset = ({ data }) => {
  return (
    <CRUDLayout.Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <ThFixed>Tanggal</ThFixed>
          <ThFixed>Kondisi Aset</ThFixed>
          <Th>Keterangan</Th>
        </Tr>
      </THead>

      <TBody>
        {data?.pemeliharaan?.length > 0 ? 
          data.pemeliharaan.map((val, index) => {
            return (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{DateConvert(new Date(val.timestamp_histori_pemeliharaan_aset)).defaultDMY}</TdFixed>
                <TdFixed>{val.nama_kondisi_aset}</TdFixed>
                <Td>{val.keterangan}</Td>
              </Tr>
            )
          })
        :
          <Tr>
            <TdFixed colSpan="4">Tidak ada data</TdFixed>
          </Tr>
        }
      </TBody>
    </CRUDLayout.Table>
  )
}

export default PemeliharaanAset
