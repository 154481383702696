import { Services } from "../../../services";

class KategoriAsetApi {
  getPage(page, dataLength, key) {
    return Services.get(`/kategoriaset/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  single(value) {
    return Services.get(`/kategoriaset/single`, { params: { ...value } });
  }
  getGrupAset() {
    return Services.get("/grupaset/dropdown");
  }
  getKodeGrup(id_grup_aset = 1) {
    return Services.get(`/kategoriaset/no_baru?id_grup_aset=${id_grup_aset}`);
  }
  create(data) {
    return Services.post("/kategoriaset", data);
  }
  update(data) {
    return Services.put("/kategoriaset", data);
  }
  delete(id_kategoriaset) {
    return Services.post("/kategoriaset/delete", id_kategoriaset);
  }
  show(id_kategoriaset) {
    return Services.put("/kategoriaset/show", id_kategoriaset);
  }
  hide(id_kategoriaset) {
    return Services.put("/kategoriaset/hide", id_kategoriaset);
  }
}

export default new KategoriAsetApi();
