import {
  useState,
  useEffect
} from 'react'
import {
  Col,
  Modal, Row
} from 'react-bootstrap'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'
import { 
  ActionButton,
  DataStatus, 
  DatePicker, 
  Input,
  SelectSearch,
  TextArea,
  InfoItemHorizontal, 
  InfoItemVertical
} from 'components'
import { 
  DateConvert 
} from 'utilities'
import {
  PemeliharaanAsetApi
} from 'api'

const dummyFetch = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve('ok')
  }, 300)
})
const dummyNomor = date => new Promise((resolve, reject) => {
  setTimeout(() => {
    return resolve({data: {data: 'NOBARU' + date}})
  }, 300);
})

const ModalSection = ({modalConfig, alertConfig, setModalConfig, setAlertConfig, refreshList}) => {
  const STATUS = modalConfig.type
  const ID = modalConfig.id
  const [dataInfo, setDataInfo] = useState({})
  const [dataGrupAset, setDataGrupAset] = useState([])
  const [dataKategoriAset, setDataKategoriAset] = useState([])
  const [dataJenisAset, setDataJenisAset] = useState([])
  const [dataAset, setDataAset] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false  
  })

  // Mapping data
  const mappingDataInfo = data => {
    return {
      id_pemeliharaan: data.id_pemeliharaan_aset ?? null,
      id_grup: data.id_grup_aset ?? null,
      id_kategori: data.id_kategori_aset ?? null,
      id_jenis: data.id_jenis_aset ?? null,
      id_aset: data.id_item_aset ?? null,
      tanggal: data.tgl_pemeliharaan_aset ?? null,
      nomor: data.no_pemeliharaan_aset ?? null,
      nama_grup: data.nama_grup_aset ?? null,
      nama_kategori: data.nama_kategori_aset ?? null,
      nama_jenis: data.nama_jenis_aset ?? null,
      nama_aset: data.nama_item_aset ?? null,
      penanggung_jawab: data.nama_karyawan ?? null,
      unit_organisasi: data.nama_unit_organisasi ?? null,
      status_pemeliharaan: data.status_pemeliharaan_aset ?? null,
      catatan: data.catatan_pemeliharaan_aset ?? null
    }
  }
  const mappingDataGrupAset = data => data.map(val => {
    return {
      value: val.id_grup_aset ?? null,
      label: val.nama_grup_aset ?? null
    }
  })
  const mappingDataKategoriAset = data => data.map(val => {
    return {
      value: val.id_kategori_aset ?? null,
      label: val.nama_kategori_aset ?? null,
      id_grup: val.id_grup_aset ?? null
    }
  })
  const mappingDataJenisAset = data => data.map(val => {
    return {
      value: val.id_jenis_aset ?? null,
      label: val.nama_jenis_aset ?? null,
      id_kategori: val.id_kategori_aset ?? null
    }
  })
  const mappingDataAset = data => data.map(val => {
    return {
      value: val.id_item_aset ?? null,
      label: val.nama_item_aset ?? null,
      id_jenis: val.id_jenis_aset ?? null
    }
  })
  // Menangani keperluan formik
  const formInitialValues = {
    tanggal: STATUS === 'update' ? dataInfo.tanggal : '',
    nomor: STATUS === 'update' ? dataInfo.nomor : '',
    id_grup: STATUS === 'update' ? dataInfo.id_grup : '',
    id_kategori: STATUS === 'update' ? dataInfo.id_kategori : '',
    id_jenis: STATUS === 'update' ? dataInfo.id_jenis : '',
    id_aset: STATUS === 'update' ? dataInfo.id_aset : '',
    catatan: STATUS === 'update' ? dataInfo.catatan : ''
  }
  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required('Pilih tanggal'),
    nomor: Yup.string().required('Pilih tanggal untuk menentukan nomor'),
    id_grup: Yup.string().required('Pilih grup aset').nullable(),
    id_kategori: Yup.string().required('Pilih kategori aset').nullable(),
    id_jenis: Yup.string().required('Pilih jenis aset').nullable(),
    id_aset: Yup.string().required('Pilih nama aset').nullable(),
    catatan: Yup.string().required('Masukan catatan'),
  })
  const formSubmitHandler = values => {
    const createData = () => {
      const finalValues = {
        tgl_pemeliharaan_aset: values.tanggal,
        id_item_aset: values.id_aset,
        catatan_pemeliharaan_aset: values.catatan
      }

      PemeliharaanAsetApi.create(finalValues)
        .then(() => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          })
          refreshList()
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal ditambah!",
          })
        })
        .finally(() => {
          setModalConfig({
            show: false,
            type: '',
            id: ''
          })
        })
    }

    const updateData = () => {
      const finalValues = {
        id_pemeliharaan_aset: ID,
        tgl_pemeliharaan_aset: values.tanggal,
        id_item_aset: values.id_aset,
        catatan_pemeliharaan_aset: values.catatan
      }

      PemeliharaanAsetApi.update(finalValues)
        .then(() => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          })
          refreshList()
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal ditambah!",
          })
        })
        .finally(() => {
          setModalConfig({
            show: false,
            type: '',
            id: ''
          })
        })
    }

    return STATUS === 'update' ? updateData() : createData()
  }

  // Fetch data awal pada saat modal detail dibuka
  const getDetailInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      PemeliharaanAsetApi.getSingle({id_pemeliharaan_aset: ID}),
    ])
      .then(Axios.spread((info) => {
        const rawDataInfo = info.data.data
        const mapDataInfo = mappingDataInfo(rawDataInfo ?? {})

        setDataInfo(mapDataInfo)
        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  // Fetch data awal pada saat modal tambah dibuka
  const getCreateInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      PemeliharaanAsetApi.getDropdown({tipe: 'grup_aset'}),
      PemeliharaanAsetApi.getDropdown({tipe: 'kategori_aset'}),
      PemeliharaanAsetApi.getDropdown({tipe: 'jenis_aset'}),
      PemeliharaanAsetApi.getDropdown({tipe: 'item_aset'})
    ])
      .then(Axios.spread((grup, kategori, jenis, aset) => {
        const rawDataGrup = grup.data.data
        const rawDataKategori = kategori.data.data
        const rawDataJenis = jenis.data.data
        const rawDataAset = aset.data.data
        const mapDataGrup = mappingDataGrupAset(rawDataGrup ?? [])
        const mapDataKategori = mappingDataKategoriAset(rawDataKategori ?? [])
        const mapDataJenis = mappingDataJenisAset(rawDataJenis ?? [])
        const mapDataAset = mappingDataAset(rawDataAset ?? [])

        setDataGrupAset(mapDataGrup)
        setDataKategoriAset(mapDataKategori)
        setDataJenisAset(mapDataJenis)
        setDataAset(mapDataAset)
        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  // Fetch data awal pada saat modal ubah dibuka
  const getUpdateInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      PemeliharaanAsetApi.getSingle({id_pemeliharaan_aset: ID}),
      PemeliharaanAsetApi.getDropdown({tipe: 'grup_aset'}),
      PemeliharaanAsetApi.getDropdown({tipe: 'kategori_aset'}),
      PemeliharaanAsetApi.getDropdown({tipe: 'jenis_aset'}),
      PemeliharaanAsetApi.getDropdown({tipe: 'item_aset'})
    ])
      .then(Axios.spread((info, grup, kategori, jenis, aset) => {
        const rawDataInfo = info.data.data
        const rawDataGrup = grup.data.data
        const rawDataKategori = kategori.data.data
        const rawDataJenis = jenis.data.data
        const rawDataAset = aset.data.data
        const mapDataInfo = mappingDataInfo(rawDataInfo ?? {})
        const mapDataGrup = mappingDataGrupAset(rawDataGrup ?? [])
        const mapDataKategori = mappingDataKategoriAset(rawDataKategori ?? [])
        const mapDataJenis = mappingDataJenisAset(rawDataJenis ?? [])
        const mapDataAset = mappingDataAset(rawDataAset ?? [])

        setDataInfo(mapDataInfo)
        setDataGrupAset(mapDataGrup)
        setDataKategoriAset(mapDataKategori)
        setDataJenisAset(mapDataJenis)
        setDataAset(mapDataAset)
        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  useEffect(() => {
    STATUS === 'detail' && getDetailInitialData()
    STATUS === 'create' && getCreateInitialData()
    STATUS === 'update' && getUpdateInitialData()
  }, [])

  // Tampilan modal body pada saat lihat detail
  const ModalDetail = () => {
    return (
      <Modal.Body>
        <InfoItemHorizontal 
          label="Tgl. Pemeliharaan Aset"
          text={dataInfo.tanggal ? DateConvert(new Date(dataInfo.tanggal)).detail : '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="No. Pemeliharaan Aset"
          text={dataInfo.nomor ?? '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="Item Aset"
          text={dataInfo.nama_aset ?? '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="Grup Aset"
          text={dataInfo.nama_grup ?? '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="Kategori Aset"
          text={dataInfo.nama_kategori ?? '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="Jenis Aset"
          text={dataInfo.nama_aset ?? '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="Penanggung Jawab"
          text={dataInfo.penanggung_jawab ?? '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="Unit Organisasi"
          text={dataInfo.unit_organisasi ?? '-'}
          width={150}
        />
        <InfoItemHorizontal 
          label="Status Pemeliharaan"
          text={dataInfo.status_pemeliharaan ?? '-'}
          width={150}
        />
        <hr />
        <InfoItemVertical 
          label="Catatan Pemeliharaan"
          text={dataInfo.catatan ?? '-'}
        />
      </Modal.Body>
    )
  }

  // Tampilan modal body pada saat tambah/ubah
  const ModalForm = ({formik, dataInfo}) => {
    const {values, errors, touched, setValues, setFieldValue, handleChange, handleSubmit, isSubmitting} = formik
    const [filteredDataKategori, setFilteredDataKategori] = useState([])
    const [filteredDataJenis, setFilteredDataJenis] = useState([])
    const [filteredDataAset, setFilteredDataAset] = useState([])
    const [formLoading, setFormLoading] = useState({
      nomor: false,
      kategori: false,
      jenis: false,
      aset: false
    })
    
    // Check select search disable
    const isKategoriDisabled = Boolean(values.id_grup) ? false : true
    const isJenisDisabled = Boolean(values.id_kategori && values.id_kategori) ? false : true
    const isAsetDisabled = Boolean(values.id_kategori && values.id_kategori && values.id_jenis) ? false : true
    
    // Filter data select
    const filterKategoriHandler = id => dataKategoriAset.filter(val => val.id_grup === id)
    const filterJenisHandler = id => dataJenisAset.filter(val => val.id_kategori === id)
    const filterAsetHandler = id => dataAset.filter(val => val.id_jenis === id)
    
    // Menangani saat tangal pada form diubah
    const onChangeTanggalHandler = date => {
      if (STATUS === 'update') {
        return setFieldValue('tanggal', date)
      } else {
        setFormLoading({
          ...formLoading,
          nomor: true
        })

        PemeliharaanAsetApi.getNomor({tanggal: date})
          .then(res => {
            const noBaru = res.data.data

            setValues({
              ...values,
              nomor: noBaru,
              tanggal: date
            })
          })
          .catch(() => {
            setValues({
              ...values,
              nomor: '',
              tanggal: ''
            })
          })
          .finally(() => {
            setFormLoading({
              ...formLoading,
              nomor: false
            })
          })
      }
    }

    // Menangani saat select grup aset diubah
    const onChangeGrupHandler = id => {
      setFormLoading({...formLoading, kategori: true})
      setValues({
        ...values,
        id_grup: id,
        id_kategori: '',
        id_jenis: '',
        id_aset: ''
      })

      const filter = filterKategoriHandler(id)

      setFilteredDataKategori(filter)
      setFilteredDataJenis([])
      setFilteredDataAset([])
      setTimeout(() => {
        setFormLoading({...formLoading, kategori: false})
      }, 100)
    }

    // Menangani saat select kategori aset diubah
    const onChangeKategoriHandler = id => {
      setFormLoading({...formLoading, jenis: true})
      setValues({
        ...values,
        id_kategori: id,
        id_jenis: '',
        id_aset: ''
      })
      
      const filter = filterJenisHandler(id)
      
      setFilteredDataJenis(filter)
      setFilteredDataAset([])
      setTimeout(() => {
        setFormLoading({...formLoading, jenis: false})
      }, 100)
    }

    // Menangani saat select jenis aset diubah
    const onChangeJenisHandler = id => {
      setFormLoading({...formLoading, aset: true})
      setValues({
        ...values,
        id_jenis: id,
        id_aset: ''
      })
      
      const filter = filterAsetHandler(id)

      setFilteredDataAset(filter)
      setTimeout(() => {
        setFormLoading({...formLoading, aset: false})
      }, 100)
    }

    useEffect(() => {
      STATUS === 'create' && onChangeTanggalHandler(DateConvert(new Date()).default)

      setFilteredDataKategori(filterKategoriHandler(dataInfo.id_grup ?? []))
      setFilteredDataJenis(filterJenisHandler(dataInfo.id_kategori ?? []))
      setFilteredDataAset(filterAsetHandler(dataInfo.id_jenis ?? []))
    }, [])
   
    return (
      <>
        <Modal.Body>
          <Row>
            {/* Tgl. Pemeliharaan Aset */}
            <Col>
              <DatePicker 
                label="Tgl. Pemeliharan Aset"
                placeholderText="Pilih tgl. pemeliharaan aset"
                selected={values.tanggal ? new Date(values.tanggal) : ''}
                onChange={date => onChangeTanggalHandler(DateConvert(date).default)}
                error={Boolean(errors.tanggal && touched.tanggal)}
                errorText={Boolean(errors.tanggal && touched.tanggal) && errors.tanggal}
                />
            </Col>

            {/* No. Pemeliharaan Aset */}
            <Col>
              <Input 
                readOnly
                label="No. Pemeliharaan Aset"
                placeholder="Pilih tgl. untuk menentukan nomor"
                value={formLoading.nomor ? 'Memuat data . . .' : values.nomor}
                error={Boolean(errors.nomor && touched.nomor)}
                errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
              />
            </Col>
          </Row>

          {/* Grup Aset */}
          <SelectSearch 
            label="Grup Aset"
            placeholder="Pilih grup aset"
            option={dataGrupAset}
            defaultValue={values.id_grup ? dataGrupAset.find(val => val.value === values.id_grup) : ''}
            onChange={opt => onChangeGrupHandler(opt.value)}
            error={Boolean(errors.id_grup && touched.id_grup)}
            errorText={Boolean(errors.id_grup && touched.id_grup) && errors.id_grup}
          />

          {/* Kategori Aset */}
          <SelectSearch 
            label="Kategori Aset"
            placeholder="Pilih kategori aset"
            option={filteredDataKategori}
            defaultValue={values.id_kategori ? dataKategoriAset.find(val => val.value === values.id_kategori) : ''}
            onChange={opt => onChangeKategoriHandler(opt.value)}
            error={Boolean(errors.id_kategori && touched.id_kategori)}
            errorText={Boolean(errors.id_kategori && touched.id_kategori) && errors.id_kategori}
            isDisabled={isKategoriDisabled}
            loading={formLoading.kategori}
          />

          {/* Jenis Aset */}
          <SelectSearch 
            label="Jenis Aset"
            placeholder="Pilih jenis aset"
            option={filteredDataJenis}
            defaultValue={values.id_jenis ? dataJenisAset.find(val => val.value === values.id_jenis) : ''}
            onChange={opt => onChangeJenisHandler(opt.value)}
            error={Boolean(errors.id_jenis && touched.id_jenis)}
            errorText={Boolean(errors.id_jenis && touched.id_jenis) && errors.id_jenis}
            isDisabled={isJenisDisabled}
            loading={formLoading.jenis}
          />

          {/* Nama Aset */}
          <SelectSearch 
            label="Nama Aset"
            placeholder="Pilih nama aset"
            option={filteredDataAset}
            defaultValue={values.id_aset ? dataAset.find(val => val.value === values.id_aset) : ''}
            onChange={opt => setFieldValue('id_aset', opt.value)}
            error={Boolean(errors.id_aset && touched.id_aset)}
            errorText={Boolean(errors.id_aset && touched.id_aset) && errors.id_aset}
            isDisabled={isAsetDisabled}
            loading={formLoading.aset}
          />

          {/* Catatan */}
          <TextArea 
            label="Catatan Pemeliharaan"
            name="catatan"
            placeholder="Masukan catatan pemeliharaan"
            value={values.catatan}
            onChange={handleChange}
            error={Boolean(errors.catatan && touched.catatan)}
            errorText={Boolean(errors.catatan && touched.catatan) && errors.catatan}
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton 
            onClick={handleSubmit}
            text={STATUS === 'update' ? 'Ubah Data' : 'Tambah Data'}
            variant={STATUS === 'update' ? 'success' : 'primary'}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )
  }

  // Tampilan pada saat loading/gagal memuat data
  if (fetchingStatus.loading || !fetchingStatus.success) {
    return (
      <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({
        show: false,
        type: '',
        id: ''
      })}
    >
      <Modal.Header closeButton>
        <b>
          {STATUS === 'detail'
            ? 'Detail Pemeliharaan Aset'
            : STATUS === 'update'
              ? 'Ubah Pemeliharaan Aset'
              : 'Tambah Pemeliharaan Aset'
          }
        </b>
      </Modal.Header>
      <Modal.Body>
        {
          fetchingStatus.loading
            ? <DataStatus loading text="Memuat data . . . " />
            : <DataStatus text="Data gagal dimuat!" />
        }
      </Modal.Body>
    </Modal>
    )
  }

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({
        show: false,
        type: '',
        id: ''
      })}
    >
      <Modal.Header closeButton>
        <b>
          {STATUS === 'detail'
            ? 'Detail Pemeliharaan Aset'
            : STATUS === 'update'
              ? 'Ubah Pemeliharaan Aset'
              : 'Tambah Pemeliharaan Aset'
          }
        </b>
      </Modal.Header>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {formik => STATUS === 'detail'
          ? <ModalDetail />
          : <ModalForm 
              formik={formik} 
              dataInfo={dataInfo}
            />
        }
      </Formik>
    </Modal>
  )
}

export default ModalSection
