import { Services } from "../../../services";

class GrupAsetApi {
  getPage(page, dataLength) {
    return Services.get(`/grupaset/page/?page=${page}&per_page=${dataLength}`);
  }
  create(data) {
    return Services.post("/grupaset", data);
  }
  update(data) {
    return Services.put("/grupaset", data);
  }
  delete(id_grupaset) {
    return Services.post("/grupaset/delete", id_grupaset);
  }
  search(key) {
    return Services.get(`grupaset/page/?q=${key}`);
  }
  show(id_grupaset) {
    return Services.put("/grupaset/show", id_grupaset);
  }
  hide(id_grupaset) {
    return Services.put("/grupaset/hide", id_grupaset);
  }
}

export default new GrupAsetApi();
