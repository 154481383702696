// React
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// API
import { TransferProduksiAlatMesinApi } from "api";

// Component
import { InputSearch, Alert, Pagination, Th, Td, DataStatus, Table as MyTable } from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { Button, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";

// Icon
import { IoAddOutline } from "react-icons/io5";

import { useIsGuest } from "hooks";

const TransferProduksiAlatMesin = ({ setNavbarTitle }) => {
  const title = "Transfer Produksi Alat Mesin";
  const guest = useIsGuest();

  const history = useHistory();

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // data
  const [data, setData] = useState([
    {
      no_permintaan_produksi: "1",
    },
  ]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    // request data ke server
    TransferProduksiAlatMesinApi.page({
      page: page,
      per_page: dataLength,
      q: searchKey,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfigSearch({
            data: true,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const Table = () => {
    return (
      <>
        <MyTable>
          <thead>
            <tr className="text-center">
              <Th>No</Th>
              <Th>Tgl Job Order</Th>
              <Th>No Job Order</Th>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Qty</Th>
              {guest ? "" : <Th>Aksi</Th>}
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <Td className="text-center align-middle">{TableNumber(page, dataLength, index)}</Td>
                <Td className="align-middle">
                  {val.tgl_job_order ? DateConvert(new Date(val.tgl_job_order)).detail : "-"}
                </Td>
                <Td className="text-center align-middle">{val.no_job_order}</Td>
                <Td className="align-middle">{val.nama_customer}</Td>
                <Td className="align-middle">{val.nama_barang_jadi}</Td>
                <Td className="align-middle">{val.qty_rap ? parseFloat(val.qty_rap) : 0}</Td>
                {guest ? (
                  ""
                ) : (
                  <Td className="text-center align-middle">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Tambah</Tooltip>}>
                      <Button
                        variant="primary"
                        size="sm"
                        className="m-1"
                        onClick={() =>
                          history.push(`/transaksi/transfer-produksi-alat-mesin/data`, {
                            id_job_order: val.id_job_order,
                            id_barang_jadi: val.id_barang_jadi,

                            ref_uid: val.ref_uid,
                          })
                        }
                      >
                        <IoAddOutline />
                      </Button>
                    </OverlayTrigger>
                  </Td>
                )}
              </tr>
            ))}
          </tbody>
        </MyTable>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataCount={totalData}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      </>
    );
  };

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-md-6">
          <Row className="mb-3">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </div>
      </div>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        // <h1>Hello World</h1>
        <DataStatus text="Tidak ada data" />
      )}
    </>
  );
};

export default TransferProduksiAlatMesin;
