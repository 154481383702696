// React
import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

// Component
import { Button } from "react-bootstrap";
import { CRUDLayout, DataStatus, Alert } from "../../../../../components";

// Icon
import { IoArrowBackOutline } from "react-icons/io5";

// Page
import { 
  InformasiUmum, BerkasAset, PenyusutanAset, PemeliharaanAset 
} from "./Page";
import { AsetSistemApi } from "../../../../../api";

const DataAsetSistem = ({ setNavbarTitle }) => {
	const { tipe, id } = useParams()
	const title = "Aset Sistem-Sistem"
  const history = useHistory();
  const location = useLocation()
  const routerState = location.state
  const [data, setData] = useState({
    id_item_aset: "",
    id_entitas_aset: "",
    nama_entitas_aset: "",
    id_grup_aset: "",
    id_kategori_aset: "",
    nama_kategori_aset: "",
    id_jenis_aset: "",
    nama_jenis_aset: "",
    tahun_perolehan: "",
    harga_perolehan: "0",
    kode_aset: "",
    nama_aset: "",
    id_kondisi_aset: "",
    nama_kondisi_aset: "",
    id_karyawan: "",
    nama_karyawan: "",
    id_unit_organisasi: "",
    nama_unit_organisasi: "",
    keterangan: "",
    id_provinsi: "",
    nama_provinsi: "",
    id_kabupaten: "",
    nama_kabupaten: "",
    id_kecamatan: "",
    nama_kecamatan: "",
    id_desa: "",
    nama_desa: "",
    detail_lokasi: "",
    penyusutan_garis_lurus_per_tahun: "",
    penyusutan_garis_lurus_per_bulan: "",
    file: [],
    penyusutan: [],
    produksi: false,
  });
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [alertHandler, setAlertHandler] = useState({
    show: false,
    variant: "primary",
    text: "Data karyawan berhasil ditambah",
  });

  const getDataSingle = () => {
    setIsLoading(true)

    if (tipe == "ubah" || tipe == "detail") {
      AsetSistemApi.single({id_item_aset: id})
      .then(res => setData({
        ...data,
        ...res.data.data
      }))
      .finally(() => setIsLoading(false))
		} else {
      AsetSistemApi.single({id_item_aset: data.id_item_aset})
      .then(res => setData({
        ...data,
        ...res.data.data
      }))
      .finally(() => setIsLoading(false))
    }
  }

  useEffect(() => {
    setIsLoading(true)

		setNavbarTitle(`${tipe.toUpperCase()} ${title}`)
		if (tipe == "ubah" || tipe == "detail") {
			getDataSingle()
		}

    setIsLoading(false)
  }, [setNavbarTitle]);

  const PageIndicator = () => {
    const PageIndicatorItems = ({ number, text, active, onClick }) => (
      <div
        className="d-flex flex-column justify-content-center align-items-center mx-2"
        style={{
          width: "100px",
          cursor: onClick ? "pointer" : "default",
        }}
        onClick={onClick}
      >
        <div
          className={`d-flex justify-content-center align-items-center mb-2 rounded-pill text-white shadow-sm ${
            active && "bg-primary"
          }`}
          style={{
            height: "30px",
            width: "30px",
            background: !active && "#C1C1C1",
          }}
        >
          {number}
        </div>
        <small>{text}</small>
      </div>
    );

    return (
      <div
        className="d-flex justify-content-center align-items-center mb-2"
        style={{ position: "relative" }}
      >
        <div
          className="bg-secondary"
          style={{
            height: "1px",
            width: "280px",
            position: "absolute",
            marginTop: "-25px",
            opacity: 0.3,
            zIndex: 1,
          }}
        ></div>
        <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 1 }}>
          <PageIndicatorItems
            number="1"
            text="Informasi Umum"
            active={page === 1 && true}
            onClick={() => tipe != "tambah" && setPage(1)}
          />
          <PageIndicatorItems
            number="2"
            text="Penyusutan Aset"
            active={page === 2 && true}
            onClick={() => tipe != "tambah" && setPage(2)}
          />
          <PageIndicatorItems
            number="3"
            text="Gambar Aset"
            active={page === 3 && true}
            onClick={() => tipe != "tambah" && setPage(3)}
          />
          {tipe !== "tambah" &&
            <PageIndicatorItems
              number="4"
              text="Pemeliharaan"
              active={page === 4 && true}
              onClick={() => tipe != "tambah" && setPage(4)}
            />
          }
        </div>
      </div>
    );
  };

  const Form = () => {
    return (
      <div className="d-flex flex-column align-items-center">
        <PageIndicator />
        <div className="col col-md-12 col-lg-12 p-0">
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            {page === 1 && <b>Informasi Umum</b>}
            {page === 2 && <b>Penyusutan Aset</b>}
            {page === 3 && <b>Gambar Aset</b>}
            {page === 4 && <b>Pemeliharaan Aset</b>}

            <div>
              <Button
                variant="outline-secondary"
                size="sm"
                className="mx-1 mb-1"
                onClick={() => history.push('/asm/master/item-aset/aset-sistem', {...routerState})}
              >
                <IoArrowBackOutline size={20} />
                <span className="ml-2">Kembali Ke Tabel</span>
              </Button>
            </div>
          </div>
          <Alert
            show={alertHandler.show}
            variant={alertHandler.variant}
            text={alertHandler.text}
            showCloseButton={true}
            onClose={() => setAlertHandler({ ...alertHandler, show: false })}
          />
        </div>
        <div className="col col-md-12 col-lg-12 mb-3 p-3">
          {page === 1 && <InformasiUmum tipe={tipe} data={data} setData={setData} setPage={setPage} />}
          {page === 2 && <PenyusutanAset tipe={tipe} data={data} setPage={setPage} />}
          {page === 3 && <BerkasAset tipe={tipe} data={data} getDataSingle={getDataSingle} />}
          {page === 4 && <PemeliharaanAset data={data} />}
        </div>
      </div>
    );
  };

  if (tipe === 'tambah' || tipe === 'ubah' || tipe === 'detail') {
    return (
      <CRUDLayout>
        {isLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <Form />}
      </CRUDLayout>
    );
  } else {
    return <DataStatus text="Tidak Ada Data" />
  }
};

export default DataAsetSistem;
