import { Services, UploadServices } from "../../../../services";

class AsetTanahApi {
  getPage(page, dataLength, key) {
    return Services.get(`/asettanah/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }

  getKode() {
    return Services.get("/asettanah/no_baru?id_kategori_aset=20");
  }

  getKategoriAset() {
    return Services.get("/asettanah/kategori");
  }

  getKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  getUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  getProvinsi() {
    return Services.get("/wilayah/dropdown_provinsi");
  }

  getKabupaten(id) {
    return Services.get("/wilayah/dropdown_kabupaten?id_provinsi=" + id);
  }

  getKecamatan(id) {
    return Services.get("/wilayah/dropdown_kecamatan?id_kabupaten=" + id);
  }

  getDesa(id) {
    return Services.get("/wilayah/dropdown_desa?id_kecamatan=" + id);
  }

  getSingle(id_item_aset) {
    return Services.get("/asettanah/single/?id_item_aset=" + id_item_aset);
  }

  create(data) {
    return Services.post("/asettanah", data);
  }

  update(data) {
    return Services.put("/asettanah", data);
  }

  delete(id) {
    return Services.post("/asettanah/delete", id);
  }

  show(id) {
    return Services.put("/asettanah/show", id);
  }

  hide(id) {
    return Services.put("/asettanah/hide", id);
  }

  getKondisi() {
    return Services.get("/asettanah/dropdown_kondisi_aset");
  }

  uploadFileMultiple(data) {
    return UploadServices.post("/upload/aset_multiple", data);
  }

  uploadFile(data) {
    return Services.post("/asettanah/gambar", data);
  }

  deleteFile(id) {
    return Services.post("/asettanah/gambar_delete", id);
  }

  dropdown(value) {
    return Services.get("/asettanah/dropdown", { params: { ...value } });
  }

  single(value) {
    return Services.get("/asettanah/single", { params: { ...value } });
  }

  no_baru(value) {
    return Services.get("/asettanah/no_baru", { params: { ...value } });
  }

  singleCheck(value) {
    return Services.get("/asettanah/single_check", { params: { ...value } });
  }
  export() {
    return Services.get("/asettanah/xlsx");
  }
}

export default new AsetTanahApi();
