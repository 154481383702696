import { Services } from "services";

class TransferProduksiAlatMesinApi {
  page(value) {
    return Services.get("/transfer_produksi_alat_mesin/page", { params: { ...value } });
  }

  single(value) {
    return Services.get("/transfer_produksi_alat_mesin/single", { params: { ...value } });
  }

  transfer(value) {
    return Services.get("/transfer_produksi_alat_mesin/transfer", { params: { ...value } });
  }

  dropdown(value) {
    return Services.get("/transfer_produksi_alat_mesin/dropdown", { params: { ...value } });
  }

  no_baru(value) {
    return Services.get("/transfer_produksi_alat_mesin/no_baru", { params: { ...value } });
  }

  create(value) {
    return Services.post("/transfer_produksi_alat_mesin", value);
  }

  delete(value) {
    return Services.post("/transfer_produksi_alat_mesin/delete", value);
  }
}

export default new TransferProduksiAlatMesinApi();
